import { ValidationObserver, ValidationProvider } from "vee-validate";
import Moment from "moment";

export default {
  name: "MDepositBilyetInventory",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      form: {
        bilyetNumber: "",
        sequence: 0,
        bilyetTypeId: "",
        depositBilyetId: "",
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
      },
      property: {
        animation: {
          submitLoading: false,
        },
        modal: {
          formDepositBilyetInventory: false,
        },
        listElement: {
          inventoryBilyet: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
        filterDto: {
          depositBilyetNumber: "",
          bilyetTypeId: "",
          statusId: "",
        },
      },
      options: {
        kategoriBilyet: [],
        statusAccount: [],
        form: { jenisBilyet: [] },
      },
      table: {
        header: {
          inventoryBilyet: [],
        },
        data: {
          inventoryBilyet: [],
        },
      },
    };
  },
  methods: {
    Moment: Moment,
    async authorizedBulkDepositBilyet() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      try {
        const getTotalUnAuthorized = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "transaction",
          reqUrl: "deposit-bilyet/total-unauthorized",
        });
        if (getTotalUnAuthorized.data.code === "SUCCESS") {
          if (getTotalUnAuthorized.data.data === 0) {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: "Tidak Terdapat Data Yang Belum Otorisasi",
              confirmText: "Ok",
              type: "is-danger",
            });
            return;
          }

          const confirm = await this.simpleConfirmation(
            "Otorisasi Data Ini ?",
            "is-success"
          );
          if (!confirm) return;
          this.property.animation.submitLoading = true;
          try {
            const resp = await this.$store.dispatch({
              type: "PUT_DATA_AUTHORIZED",
              endPoint: "transaction",
              reqUrl: "deposit-bilyet/authorized/bulk",
            });
            await this.simpleWait(1000);
            if (resp.data.code === "SUCCESS") {
              this.$buefy.snackbar.open(
                this.$NotificationUtils.snackbarSuccess
              );
              this.property.listElement.inventoryBilyet.currentPage = 1;
              this.property.filterDto.inventoryBilyet = "";
              this.property.filterDto.inventoryBilyet = "";
              this.property.listElement.inventoryBilyet.rows = 0;
              this.getInventoryBilyet();
            } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
              this.$buefy.dialog.alert({
                title: "Harap Tunggu !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
            } else {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: `${resp.data.message}`,
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          } catch (error) {
            await this.simpleWait(500);
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? `${error.response.statusText}`
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          } finally {
            await this.simpleWait(500);
            this.property.animation.submitLoading = false;
          }
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: `${getTotalUnAuthorized.data.message}`,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        setTimeout(() => {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? `${error.response.statusText}`
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        }, 500);
      }
    },
    async findBilyetInventory() {
      this.table.data.inventoryBilyet = [];
      this.property.listElement.inventoryBilyet.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-bilyet/inventory/simple-list",
          payload: {
            depositBilyetNumber: this.property.filterDto.depositBilyetNumber,
            bilyetTypeId: this.property.filterDto.bilyetTypeId,
            bilyetStatusId: "",
            statusId: this.property.filterDto.statusId,
            page: 0,
            size: this.property.listElement.inventoryBilyet.perPage,
            sortBy: "",
          },
        });
        await this.simpleWait(1000);
        if (resp.data.code === "SUCCESS") {
          this.table.data.inventoryBilyet = resp.data.data.content;
          this.property.listElement.inventoryBilyet.currentPage =
            resp.data.data.pageable.pageNumber + 1;
          this.property.listElement.inventoryBilyet.rows =
            resp.data.data.totalElements;
        } else {
          this.failedGetInventoryBilyet(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleErrorGetInventoryBilyet(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.inventoryBilyet.downloading = false;
      }
    },

    async deleteBilyetInventory(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Hapus Data Ini ?",
        "is-danger"
      );
      if (!confirm) return;
      try {
        const resp = await this.$store.dispatch({
          type: "DELETE_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-bilyet/" + props.row.depositBilyetId,
        });
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
          await this.simpleWait(1000);
          this.property.listElement.inventoryBilyet.currentPage = 1;
          this.property.filterDto.inventoryBilyet = "";
          this.property.filterDto.inventoryBilyet = "";
          this.property.listElement.inventoryBilyet.rows = 0;
          this.getInventoryBilyet();
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: `${resp.data.message}`,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? `${error.response.statusText}`
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    async authorizationMDepositBiletInventory(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Otorisasi Data Ini ?",
        "is-success"
      );
      if (!confirm) return;
      try {
        const resp = await this.$store.dispatch({
          type: "PUT_DATA_AUTHORIZED",
          endPoint: "transaction",
          reqUrl: "deposit-bilyet/authorized/" + props.row.depositBilyetId,
        });
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
          await this.simpleWait(1000);
          this.property.listElement.inventoryBilyet.currentPage = 1;
          this.property.filterDto.inventoryBilyet = "";
          this.property.filterDto.inventoryBilyet = "";
          this.property.listElement.inventoryBilyet.rows = 0;
          this.getInventoryBilyet();
        } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
          this.$buefy.dialog.alert({
            title: "Harap Tunggu !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: `${resp.data.message}`,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? `${error.response.statusText}`
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    handleErrorGetInventoryBilyet(error) {
      console.log(error.response);
      this.table.data.inventoryBilyet = [];
      this.property.listElement.inventoryBilyet.rows = 0;
      this.property.listElement.inventoryBilyet.currentPage = 1;
      this.property.listElement.inventoryBilyet.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },

    async changePaginationBilyetInventory(event) {
      this.table.data.inventoryBilyet = [];
      this.property.listElement.inventoryBilyet.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-bilyet/inventory/simple-list",
          payload: {
            depositBilyetNumber: this.property.filterDto.depositBilyetNumber,
            bilyetTypeId: this.property.filterDto.bilyetTypeId,
            bilyetStatusId: "",
            statusId: "",
            sortBy: "",
            page: event - 1,
            size: this.property.listElement.inventoryBilyet.perPage,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.inventoryBilyet = resp.data.data.content;
          this.property.listElement.inventoryBilyet.rows =
            resp.data.data.totalElements;
        } else {
          this.failedGetInventoryBilyet(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleErrorGetInventoryBilyet(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.inventoryBilyet.downloading = false;
      }
    },
    async getInventoryBilyet() {
      this.table.data.inventoryBilyet = [];
      this.property.listElement.inventoryBilyet.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-bilyet/inventory/simple-list",
          payload: {
            depositBilyetNumber: "",
            bilyetTypeId: "",
            statusId: "",
            bilyetStatusId: "",
            page: 0,
            size: 10,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.inventoryBilyet = resp.data.data.content;
          this.property.listElement.inventoryBilyet.rows =
            resp.data.data.totalElements;
        } else {
          this.failedGetInventoryBilyet(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleErrorGetInventoryBilyet(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.inventoryBilyet.downloading = false;
      }
    },
    closeModalInventoryBilyet() {
      this.form = {
        ...this.form,
        bilyetNumber: "",
        sequence: 0,
        bilyetTypeId: "",
        depositBilyetId: "",
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
      };
      this.property.modal.formDepositBilyetInventory = false;
    },
    openModalInventoryBilyet(payload) {
      this.form = {
        ...this.form,
        bilyetNumber: payload?.depositBilyetNumber,
        sequence: payload?.sequence,
        bilyetTypeId: payload?.bilyetTypeId,
        depositBilyetId: payload?.depositBilyetId,
        createdByUserCode: payload?.createdByUserCode,
        createdByUserName: payload?.createdByUserName,
        createdDate: payload?.createdDate,
        updatedDate: payload?.updatedDate,
        updatedByUserCode: payload?.updatedByUserCode,
        updatedByUserName: payload?.updatedByUserName,
        authorizedDate: payload?.authorizedDate,
        authorizedByUserCode: payload?.authorizedByUserCode,
        authorizedByUserName: payload?.authorizedByUserName,
      };

      this.property.modal.formDepositBilyetInventory = true;
    },
    async submitCifCompanyManagement() {
      if (!window.navigator.onLine) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      this.property.animation.submitLoading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "POST_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-bilyet/inventory",
          payload: this.form,
        });
        await this.simpleWait(1500);
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
          this.closeModalInventoryBilyet();
          this.getInventoryBilyet();
        }
      } catch (error) {
        await this.simpleWait(1500);
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? `${error.response.statusText}`
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        await this.simpleWait(1000);
        this.property.animation.submitLoading = false;
      }
    },

    failedGetInventoryBilyet(resp) {
      this.table.data.inventoryBilyet = [];
      this.property.listElement.inventoryBilyet.rows = 0;
      this.property.listElement.inventoryBilyet.message = resp.data.message;
    },
    getReferenceBilyetTypeFilterDto() {
      this.$store
        .dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "bilyet-type",
        })
        .then((resp) => {
          resp.data.data.map((i) => {
            const value = i.bilyetTypeId;
            const text = i.bilyetTypeName;
            this.options.kategoriBilyet.push({ text, value });
          });
        });
    },
    getReferenceBilyetType() {
      this.$store
        .dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "bilyet-type",
        })
        .then((resp) => {
          resp.data.data.map((i) => {
            const value = i.bilyetTypeId;
            const text = i.bilyetTypeName;
            this.options.form.jenisBilyet.push({ value, text });
          });
        });
    },
    chargingOptionsComponent() {
      this.options.kategoriBilyet.push({ value: "", text: "Semua Kategori" });
      this.options.form.jenisBilyet.push({ value: "", text: "-- Pilih --" });
    },
    async getReferenceStatusAccount() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_PARAMETER", {
          url: "status",
        });
        console.log(resp);
        if (resp.data.code === "SUCCESS") {
          this.options.statusAccount = [{ value: "", text: "-- Semua --" }];
          resp.data.data.map((index) => {
            const text = index.statusName;
            const value = index.statusId;
            this.options.statusAccount.push({ text, value });
          });
        }
      } catch (error) {}
    },
  },
  mounted() {
    this.getInventoryBilyet();
    this.getReferenceBilyetTypeFilterDto();
    this.chargingOptionsComponent();
    this.getReferenceBilyetType();
    this.getReferenceStatusAccount();
  },
};
